import { render, staticRenderFns } from "./hygiene.vue?vue&type=template&id=7580d0c8&scoped=true&"
import script from "./hygiene.vue?vue&type=script&lang=js&"
export * from "./hygiene.vue?vue&type=script&lang=js&"
import style0 from "./hygiene.vue?vue&type=style&index=0&id=7580d0c8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7580d0c8",
  null
  
)

export default component.exports