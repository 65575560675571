// 部门相关api
import request from '@/utils/request'

export function WeGetHygieneList(query) {
    return request({
        url: '/HygieneCare/WeGetHygieneList',
        method: 'get',
        params: query
    })
}

export function WeGetHygieneInfo(query) {
    return request({
        url: '/HygieneCare/WeGetHygieneInfo',
        method: 'get',
        params: query
    })
}