<template>
  <div class="warp">
    <div class="information">
      <!-- 全部 -->
      <div class="center" v-for="item in list" :key="item.index">
        <div>
          <div class="centerTitle van-ellipsis">{{ item.Name }}</div>
          <div class="Content">
            <div style="display: inline-block" class="ContentA">
              <img :src="item.PicFileUrl" alt="" />
            </div>
            <div style="display: inline-block" class="ContentB">
              <span class="van-multi-ellipsis--l3">位置：{{ item.Address }}</span>
              <span class="van-multi-ellipsis--l3">工作时间：{{ item.WorkTime }}</span>
              <span class="centertime">负责人：{{ item.LeaderCadre }}</span><br>
              <span class="centertime">联系电话：{{ item.LeaderTel }}</span>
            </div>
          </div>
        </div>
      </div>
      <van-empty description="暂无数据"  v-if="!list.length" />
    </div>
  </div>
</template>

<script>
import {
  WeGetHygieneList,
  WeGetHygieneInfo,
} from "@/api/HygieneCare";

export default {
  data() {
    return {
      listQuery: {
          page : 1, 
          limit : 15, 
          keyWord : undefined
      },
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      WeGetHygieneList(this.listQuery).then((res) => {
        this.list = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.wktime {
  padding: 0 20px;
}
.wktype {
  padding: 0 10px 10px;
}
.orginview .iconimg {
  width: 62%;
}
.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 12px 10px;
}
.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: "";
}
.listRid {
  background: rgb(238, 236, 236);
  border-radius: 15px;
  box-shadow: 1px 1px 1px #cececc;
  margin: 8px 0px;
  padding: 8px;
  font-size: 14px;
}
.listRid .listRidtitle {
  border-bottom: 1px solid #999;
  padding-bottom: 8px;
  font-size: 16px;
}
.listRidname {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}
.listRidAddress {
  padding: 3px 0px;
}
</style>